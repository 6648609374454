import React, { useEffect } from 'react';
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import PGSideBar from '../../components/pg-side-bar';

import '../../styles/cus.css';

const PatentGuide = () => {
    const seo = {
        metaDesc: 'What Are the Benefits vs Drawbacks of Filing A Provisional Patent? - Inventiv.org'
    }

    useEffect(() => {
        document?.body.classList.add('no-home');
    }, []);

    return (
        <Layout>
            <SEO title="What Are the Benefits vs Drawbacks of Filing A Provisional Patent? - Inventiv.org" canonical='/patent-guide' seo={seo} />
            {/*  */}
            <div class="sub-banner main-banner">
                <div class="container">
                    <div class="breadcrumb-area" style={{ top: 30 + '%' }}>
                        <h1 className='fw-special-title'>&nbsp;</h1>
                        <h1 className='title-banner'>What Are the Benefits vs Drawbacks of Filing A Provisional Patent?</h1>
                    </div>
                </div>
            </div>

            <section class="mt-3 mb-3 pt-3 pb-3 wow fadeInUp delay-04s">
                <div class="container ">
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <figure className='text-center'>
                            <img width="300" height="300"
            src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
            data-src="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300,h_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png"
            class="vc_single_image-img attachment-medium lazyloaded" alt="" decoding="async"
            data-srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"
            data-sizes="(max-width: 300px) 100vw, 300px" data-pagespeed-url-hash="2358126750" sizes="(max-width: 300px) 100vw, 300px"
            srcset="https://sp-ao.shortpixel.ai/client/to_avif,q_glossy,ret_img,w_300/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-300x300.png 300w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_150/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-150x150.png 150w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_100/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1-100x100.png 100w, https://sp-ao.shortpixel.ai/client/q_glossy,ret_img,w_512/https://www.inventiv.org/wp-content/uploads/2020/08/brain-head-mind-question-thinking-icon-mind-thinking-png-512_512-1.png 512w"/>
                            </figure>
                            <div class="wpb_wrapper">
                                <h2 className='h2'>What Are the Benefits vs Drawbacks of Filing A Provisional Patent?</h2>
                                <h3 className='h3'><b>1. What are the benefits of a provisional patent?</b></h3>
                                <ul style={{ listStyle: 'disc', padding: 'revert' }}>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Provides basic protection for the idea disclosed
                                        in the PPA for one year while you evaluate the business and/or technical merits of your idea.</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Costs less than full utility patent application. A
                                        PPA costs hundreds of dollars, rather than the thousands or tens of thousands of dollars to have patent
                                        lawyers prepare a full utility patent application.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Establishes an official USPTO record of your date
                                        of the invention, so you can reap the benefits of an early filing date.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Begins the clock for the Paris Convention priority
                                        year.&nbsp; That means that you have to file international applications within one year.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Allows you to use a “Patent Pending” notice to
                                        deter others from copying your idea.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Is easy to prepare. There is no formal requirement
                                        concerning format/style and formal papers, such as the Inventor’s Oath or Declaration and the
                                        Information Disclosure Statement to disclose all relevant information known to the inventors. Thus,
                                        virtually any one can draft a PPA.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Adds one year of life to a U.S. utility patent
                                        application. Because the utility application’s life begins when the PPA is converted into a utility
                                        patent application.&nbsp; So, the invention can be protected using the patent system for as long as 21
                                        years from the PPA filing date.</span></li>
                                </ul>
                                <p>&nbsp;</p>
                                <h3 className='h3'><b>2. What are the drawbacks of a provisional patent?</b></h3>
                                <ul style={{ listStyle: 'disc', padding: 'revert' }}>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Only preserves your rights for a year and does not
                                        result in a utility patent by itself. To get longer protection, you must convert the PPA to a utility
                                        patent application within one year of filing the PPA.&nbsp; If your invention is “in use” or “on sale”
                                        in the United States and you file a PPA but fail to timely convert the PPA into a utility application
                                        within the twelve month period, you may lose the right to </span><i><span
                                            style={{ fontWeight: 400 }}>ever</span></i><span style={{ fontWeight: 400 }}> patent the
                                                invention.&nbsp; That’s because the use or sale of the invention can be prior art against your own
                                                subsequent patent application.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Delays examination of the patentability of your
                                        invention. A PPA is not examined until you convert it to a full utility patent application.&nbsp;</span>
                                    </li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Triggers the one year international filing
                                        deadline.&nbsp; So, one year from filing a PPA, you need to file a [k:&nbsp; I don’t know what a pct is]
                                        (PCT) or directly file in the countries that you’d like protection in, if you want to claim the PPA’s
                                        filing date for the corresponding international applications.</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>It’s only effective in the United
                                        States.&nbsp;</span></li>
                                    <li style={{ fontWeight: 400 }}><span style={{ fontWeight: 400 }}>Is worthless if the invention changes so much so
                                        that it is not what is described in the PPA.&nbsp;</span></li>
                                </ul>
                            </div>
                            <div className='mt-5'>
                                <p>
                                <a href='/inventor-resources' className='btn btn-warning rounded-pill px-5'>Download Provisional Patent Assistant Now!</a>
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <PGSideBar></PGSideBar>
                        </div>
                    </div>

                </div>
            </section>

        </Layout>
    )
};

export default PatentGuide;